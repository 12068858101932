import React, { createElement } from 'react';
import {
  FaFileWord,
  FaFileExcel,
  FaFilePdf,
  FaFile,
} from 'react-icons/fa'
import last from 'lodash/last'
import get from 'lodash/get'
import round from 'lodash/round'
import filesize from 'filesize'

import Data from 'contexts/data'

import Box from '../../components/Box'
import Link from '../../components/Link'
import Flex from '../../components/Flex'
import ContentLayout from '../../components/ContentLayout'
import TableModule from '../../components/TableModule'
import TableMobileModule from '../../components/TableMobileModule'
import { responsive } from '../../components/ThemeProvider/theme';

import useResponsive from '../../contexts/mediaQuery/useResponsive'

const extMap = {
  doc: FaFileWord,
  docx: FaFileWord,
  xls: FaFileExcel,
  xlsx: FaFileExcel,
  pdf: FaFilePdf,
}

const extColors = {
  doc: 'blue',
  docx: 'blue',
  xls: 'green',
  xlsx: 'green',
  pdf: 'red',
}

const getIcon = filename => {
  const ext = last(filename.split('.'))
  return get(extMap, ext, FaFile)
}

const getColor = filename => {
  const ext = last(filename.split('.'))
  return get(extColors, ext, 'gray')
}

const theaderData = [
  {
    title: '檔名',
    key: 'name',
    p: responsive(0, '1em 1em'),
    Comp: Flex,
    render: (children, data) => (
      <>
        <Box width="1.75em" color={getColor(data.name)}>
          {createElement(getIcon(data.name), { size: '1.25em' })}
        </Box>
        <Box flex={1}>
          <Link color="black" href={`/downloads/${children}`} target="blank">{children}</Link>
        </Box>
      </>
    )
  },
  {
    title: '檔案大小',
    p: responsive(0, '1em 1.5em'),
    ml: responsive('1.75em', 0),
    textAlign: responsive('auto', 'center'),
    key: 'size',
    render: (children) => filesize(children)
  },
]

const DownloadPage = () => {
  const { isMobile } = useResponsive()
  return (
    <ContentLayout title="相關表單">
      <Data path="/downloads">
        {data => data && (
          isMobile ?
          <TableMobileModule keyData={theaderData} data={data} />
          :
          <TableModule theaderData={theaderData} data={data} />
        )}
      </Data>
    </ContentLayout>
  );
};

export default DownloadPage;
